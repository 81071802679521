<template>
  <div class="bottomsp" :class="show?'':'shows'">
    <ul v-if="show" class="nrp">
      <li :class="idx==0?'xz':''" @click="go(0)">
        <div>
          <img v-if="idx != 0" src="@/assets/bottom/sy.png" alt="">
          <img v-if="idx == 0" src="@/assets/bottom/syb.png" alt="">
          <p>首页</p>
        </div>
      </li>
      <li :class="idx==1?'xz':''" v-if="qx.up_predeal" @click="go(1)">
        <div>
          <img v-if="idx != 1" src="@/assets/bottom/wz.png" alt="">
          <img v-if="idx == 1" src="@/assets/bottom/wzb.png" alt="">
          <p>接诊预诊</p>
        </div>
      </li>
      <li :class="idx==2?'xz':''" v-if="qx.up_peculiar"  @click="go(2)">
        <div>
          <img v-if="idx != 2" src="@/assets/bottom/ybjc.png" alt="">
          <img v-if="idx == 2" src="@/assets/bottom/ybjcb.png" alt="">
          <p>特检科室</p>
        </div>
      </li>
      <li :class="idx==3?'xz':''" v-if="qx.up_doctor"  @click="go(3)">
        <div>
          <img v-if="idx != 3" src="@/assets/bottom/hyzx.png" alt="">
          <img v-if="idx == 3" src="@/assets/bottom/hyzxb.png" alt="">
          <p>专家诊室</p>
        </div>
      </li>
      <li :class="idx==4?'xz':''" v-if="qx.up_refraction" @click="go(4)">
        <div>
          <img v-if="idx != 4" src="@/assets/bottom/fazx.png" alt="">
          <img v-if="idx == 4" src="@/assets/bottom/fazxb.png" alt="">
          <p>屈光科</p>
        </div>
      </li>
      <li :class="idx==5?'xz':''" v-if="qx.up_optometry" @click="go(5)">
        <div>
          <img v-if="idx != 5" src="@/assets/bottom/ybzz.png" alt="">
          <img v-if="idx == 5" src="@/assets/bottom/ybzzb.png" alt="">
          <p>视光科</p>
        </div>
      </li>
      <li :class="idx==6?'xz':''" v-if="qx.up_referral" @click="go(6)">
        <div>
          <img v-if="idx != 6" src="@/assets/bottom/dzcsjm.png" alt="">
          <img v-if="idx == 6" src="@/assets/bottom/dzcsjmb.png" alt="">
          <p>眼病综合科</p>
        </div>
      </li>
      <li :class="idx==8?'xz':''" v-if="qx.up_squintamblyopia" @click="go(8)">
        <div>
          <img v-if="idx != 8" src="@/assets/bottom/yjkbg.png" alt="">
          <img v-if="idx == 8" src="@/assets/bottom/yjkbgb.png" alt="">
          <p>斜弱视</p>
        </div>
      </li>
      <li :class="idx==7?'xz':''" v-if="qx.up_balance" @click="go(7)">
        <div>
          <img v-if="idx != 7" src="@/assets/bottom/jszx.png" alt="">
          <img v-if="idx == 7" src="@/assets/bottom/jszxb.png" alt="">
          <p>结算中心</p>
        </div>
      </li>
      <li :class="idx==9?'xz':''" v-if="qx.up_report" @click="go(9)">
        <div>
          <img v-if="idx != 9" src="@/assets/bottom/yjkbg.png" alt="">
          <img v-if="idx == 9" src="@/assets/bottom/yjkbgb.png" alt="">
          <p>CRM中心</p>
        </div>
      </li>
      <li :class="idx==10?'xz':''" v-if="qx.up_aftersales" @click="go(10)">
        <div>
          <img v-if="idx != 10" src="@/assets/bottom/yjkbg.png" alt="">
          <img v-if="idx == 10" src="@/assets/bottom/yjkbgb.png" alt="">
          <p>售后管理中心</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import pf from '@/publicFn/baseFn'
export default {
  name: "bottom",
  data() {
    return {
      idx: 0,
      show: true
    }
  },
  watch: {
    '$store.state.bases.bottomIdx': function (n, o) {
      this.idx = n
    },
    '$route': {
      deep: true,
      handler(n, o) {
        if (n.padding == '/userLing') {
          this.show = true
        }
      }
    }
  },
  computed: {
    qx: function () {
      if (this.$store.state.users.user.UserPosition) {
        let qxlist = this.$store.state.users.user.UserPosition.split(','),qxitem={}
        if (qxlist && qxlist.length) {
          for (let i = 0; i < qxlist.length; i++) {
            qxitem[qxlist[i]] = qxlist[i]
          }
        }
        return qxitem
      }else {
        return  {}
      }
    }
  },
  created() {
    if (this.$route.path=='/userLing') {
      this.show = false
    }else {
      this.show = true
    }
    this.idx = this.$store.state.bases.bottomIdx
  },
  methods: {
    go(idx) {
      this.idx = idx
      if (!this.$store.state.users.CsUser) {
        this.$router.push({path: '/csLanding'})
        return
      }
      this.$store.commit('bases/upBottomIdx', idx)
      switch (idx) {
        case 0:
          this.$router.push({path: '/'})
          break
        case 1:
          this.$router.push({path: '/home/acceptsHome'})
          break
        case 2:
          this.$router.push({path: '/home/examinationEyesHome'})
          break
        case 3:
          this.$router.push({path: '/home/expertClinicHome'})
          break
        case 4:
          this.$router.push({path: '/RefractiveDepartmentHome'})
          break
        case 5:
          this.$router.push({path: '/optometryHome'})
          break
        case 6:
          this.$router.push({path: '/eyeDiseaseHome'})
          break
        case 7:
          this.$router.push({path: '/settlementCenter'})
          break
        case 8:
          this.$router.push({path: '/training/onlineTrainingHome'})
          break
        case 9:
          this.$router.push({path: '/CRMcontent'})
          break
        case 71:
          window.location.href = 'http://wzyxy.huayiyunxinxi.com/paramLogin?user_id=1&login_type=visumall&CustomerId=656BDCD5-4A88-4609-A8DD-E6225FE31175&ChooseId=DF34338D-43DA-4BCB-9D97-FC0B3AD567AB&S_OP_Id=160FB382-9D17-4991-9A17-6295AB443F31&COAddUserId=bd9d24f6-329c-4790-9088-d21e44f7e2d3&COExpertId=EA3BB634-88E5-4844-9164-C5CAC6FC0D26&COShopId=66AE31C6-06C6-4C83-982B-029D770E066A'
          break
        case 10:
          this.$router.push({path: '/AfterSaleManagementHome'})
          break
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .bottomsp {
    width: 100vw;
    height: 11vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    border-top: 1px solid #000000;
    background: #FFFFFF;
  }
  .nrp {
    display: flex;
    width: 100vw;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size:  .18rem;
    li {
      height: 100%;
      padding: 0 2vw;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 1200px) {
        width: 120px;
        font-size: 12px;
      }
      &:hover {
        img {
          width: calc(2vw + 8px);
        }
      }
    }
    .xz {background: #e7e7e7; color: #1296db;}
    p {padding-top: 7px;}
    img {
      display: block;
      width: 2vw;
      height: auto;
      margin: 0 auto;
      @media (max-width: 1200px) {
        width: 25px;
      }
    }
  }
  .noqx {
    cursor: not-allowed !important;
  }
  .shows {
    height: 0 !important;
    border: none;
  }
</style>
